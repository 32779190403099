import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

import NavItems from './NavItems';
import Work from './Work';
import Timeline from './Timeline';
import SocialButtons from './SocialButtons';

import Close from '../images/icons/close.svg';
import Avatar from '../images/avatar-purple.jpg';

type Tag = {
  "tag": string;
}

export default function Menu({setMenuOpen, isMenuOpen}) {
  const { t, i18n } = useTranslation();
  const [menu, setMenu] = useState('open'); 
  const tags = [];
  const availability = [];

  for (const tag of i18n.t<Tag[]>('menu-tags', { returnObjects: true })) {
    tags.push(tag);
  }

  for (const tag of i18n.t<Tag[]>('menu-availability-tags', { returnObjects: true })) {
    availability.push(tag);
  }

  return (
    <div className={`menu ${isMenuOpen ? `open` : `closed`}`}>
      <div className="menu__overlay" onClick={() => setMenuOpen(false)}></div>
      <div className="menu__container">  
        <header className="header--light">
          <div className="header__top">
            <button aria-label="Open menu" className="menu__close" onClick={() => setMenuOpen(false)}>
              <img src={Close} alt="close" />
            </button>
          </div>
          <div className="header__avatar">
            <img src={Avatar} alt={t('general-name')}/>
          </div>
          {/* Feed buttons */}
        </header>  
        <div className="menu__content">
          <ul className="menu__nav">
            <NavItems />
          </ul>
          <h2>{t('general-name')}</h2>
          <div className="menu__tagline">{t('menu-tagline')}</div>
          <div className="menu__subtitle">
            {t('menu-location')} <a className="link" href="https://www.linkedin.com/in/esmee-peters/" target="_blank">LinkedIn</a> 
          </div>
          <div className="tag-list">
            {tags.map(tag => (
              <div key={tag.tag} className="tag">{tag.tag}</div>)
            )}
          </div>
          <div className="menu__intro">
            <p>{t('menu-content.0.paragraph')}</p>
            <p>{t('menu-content.1.paragraph')}</p>
            <SocialButtons />
          </div>
          <div className="menu__availability">
            <h3>{t('menu-availability')}</h3>
            <div className="tag-list">
              {availability.map(tag => (
                <div key={tag.tag} className="tag tag--colored">{tag.tag}</div>)
              )}
            </div>
          </div>
          <div className="menu__work">
            <h3>{t('menu-work')}</h3>
            <Work />
          </div>
          <div className="menu__timeline">
            <h3>{t('menu-timeline')}</h3>
            <Timeline />
          </div>
        </div>     
      </div>
    </div>
  )
}
