import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Brevo from "sib-api-v3-typescript";

import setupApi from "../helpers/setupApi";

interface FormSubscription {
  mailLists: { NL: [], EN: [] };
}

export default function FormSubscription({ mailLists }: FormSubscription) {
  const { t, i18n } = useTranslation();
  const [apiBrevo, setApiBrevo] = useState({});
  const [formEmail, setFormEmail] = useState("");
  const [formUpdate, setFormUpdate] = useState("");
  const [formStatus, setFormStatus] = useState("error");
  const lists = mailLists;

  useEffect(() => {
    setApiBrevo(
      setupApi(
        "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"
      )
    );
  }, []);

  const createContact = (email: string, lists: number[]) => {
    let createContact = new Brevo.CreateContact();
    createContact.email = email;
    createContact.listIds = lists;

    return apiBrevo
      .createContact(createContact)
      .then((data) => {
        setFormStatus("success");
        setFormUpdate(t("subscription-form-success"));
      })
      .catch((data) => {
        setFormUpdate(t("subscription-form-error"));
      });
  };

  const submitHandler = (ev) => {
    ev.preventDefault();

    if (formEmail === "") {
      setFormUpdate(t("subscription-form-emtpy"));
    } else if (!formEmail.match(/.+@.+\..+/)) {
      setFormUpdate(t("subscription-form-invalid"));
    } else {
      createContact(formEmail, lists[t("general-currentLang")]).then(() =>
        setFormEmail("")
      );
    }
  };

  return (
    <form className="subscription-form" onSubmit={submitHandler}>
      <input
        className="subscription-form__input"
        type="text"
        id="email"
        name="email"
        placeholder={t("subscription-form-adres")}
        onChange={(ev) => setFormEmail(ev.target.value)}
        value={formEmail}
        autoComplete="email"
      />
      <button aria-label={t("subscription-form-button")} className="button button--form">
        {t("subscription-form-button")}
      </button>
      <p className={`subscription-form__status subscription-form__status--${formStatus}`}>
        {formUpdate}
      </p>
    </form>
  );
}
