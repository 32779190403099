import React from 'react';
import { useTranslation } from "react-i18next";

export default function Pricing() {
  return ( 
    <section className="pricing">
      <div className="grid">
        <div className="grid__column grid__column--two">
          <div className="pricing__price">
            <h3>Head of product</h3>
          </div>
        </div>
        <div className="grid__column grid__column--two">
          <div className="pricing__price">
            <h3>Consultancy / coaching </h3>
          </div>
        </div>
        <div className="grid__column grid__column--two">
          <div className="pricing__price">
            <h3>Workshops</h3>
          </div>
        </div>
      </div>
    </section>
  )
}