import React from 'react';
import { useTranslation } from "react-i18next";
import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';

interface Expertise {
  expertise: 'product' | 'development'
}

export default function ExpertiseAreas({expertise}: Expertise) {
  const { t, i18n } = useTranslation();

  return (
    <>
      { expertise === 'product' ?
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          slidesPerView={1}
          spaceBetween={20}
          speed={1000}
          loop={true}
          navigation
          pagination={
            {
              clickable: true,
              renderBullet: (index, className) => {
                const products = ['Product Management', 'Digtal Product Design', 'Software Development', 'Business Development & Analytics']
                return '<span class="' + className + '">' + products[index] + '</span>';
              }
            } 
          }
        >
          <SwiperSlide>
            <div className="swiper-content">
              <h3>Product Management</h3>
              <p>{t('frl-product-expertise-product')}</p>
              <div className="tag-list">
                <div className="tag">Agile Methologies</div>
                <div className="tag">Product Ownership</div>
                <div className="tag">Product Strategy</div>
                <div className="tag">Release Strategy</div>
                <div className="tag">Prioritzing</div>
                <div className="tag">Product-Led Growth</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-content">
              <h3>Digital Product Design</h3>
              <p>{t('frl-product-expertise-design')}</p>
              <div className="tag-list">
                <div className="tag">User Experience Design</div>
                <div className="tag">User Interface Design</div>
                <div className="tag">Product Discovery</div>
                <div className="tag">User Research</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-content">
              <h3>Software/Web Development</h3>
              <p>{t('frl-product-expertise-development')}</p>
              <div className="tag-list">
                <div className="tag">Technical Knowledge</div>
                <div className="tag">Development Cycle</div>
                <div className="tag">Software & webdevelopment</div>
                <div className="tag">Cloud/SaaS service</div>
                <div className="tag">Technical writing</div>
                <div className="tag">Testing</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-content">
              <h3>Business Development & Analytics</h3>
              <p>{t('frl-product-expertise-business')}</p>
              <div className="tag-list">
                <div className="tag">Customer & market research</div>
                <div className="tag">Business analysis</div>
                <div className="tag">Data analysis</div>
                <div className="tag">Stakeholder management</div>
                <div className="tag">Agile Project Management</div>
                <div className="tag">Digital Marketing</div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper> :
        <Swiper
        modules={[Navigation, Pagination, A11y]}
        slidesPerView={1}
        spaceBetween={20}
        speed={1000}
        loop={true}
        navigation
        pagination={
          {
            clickable: true,
            renderBullet: (index, className) => {
              const products = ['Product Management', 'Front-End Development', 'Digtal Product Design']
              return '<span class="' + className + '">' + products[index] + '</span>';
            }
          } 
        }
        >
          <SwiperSlide>
            <div className="swiper-content">
              <h3>Product Management</h3>
              <p>{t('frl-development-expertise-product')}</p>
              <div className="tag-list">
                <div className="tag">Product Ownership</div>
                <div className="tag">Product/Market Fit</div>
                <div className="tag">Product Discovery</div>
                <div className="tag">Product- and Release Strategy</div>
                <div className="tag">Product-Led Growth</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-content">
              <h3>Front-end development</h3>
              <p>{t('frl-development-expertise-frontend')}</p>
              <div className="tag-list">
                <div className="tag">HTML & CSS</div>
                <div className="tag">Javascript</div>
                <div className="tag">TypeScript</div>
                <div className="tag">Responsive</div>
                <div className="tag">Git</div>
                <div className="tag">SEO</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-content">
              <h3> Digital Product Design</h3>
              <p>{t('frl-development-expertise-design')}</p>
              <div className="tag-list">
                <div className="tag">User Experience Design</div>
                <div className="tag">User Interface Design</div>
                <div className="tag">Product Discovery</div>
                <div className="tag">User Research</div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      }
    </>
  )
}
