import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


import EsmeePeters from "../images/logos/companies/esmeepeters.svg";
import Cognifya from "../images/logos/companies/cognifya.svg";

export default function Services({ currentpage, title, description }) {
  const { t, i18n } = useTranslation();

  return (
    <section className="services">
      <div className="grid">
        <div className="grid__column grid__column--four">
          <label>{t('services-label')}</label>
          <h2 className="services__title">{title}</h2>
          <p className="services__description">{description}</p>
        </div>
      </div>
      <div className="services__showcase">
        <div className="services__container services__container--services">
          <div className="services__title">
            <h3>{t('services-title')}</h3>
          </div>
          <div className="services__links">
            <Link to="/productmanagement" className="services__service">
              <h3>{t('service1-title')}</h3>
              <p>{t('services1-description')}</p>
              <button className="button button--light">{t('services1-cta')}</button>
            </Link>
            <Link to="/productdevelopment" className="services__service">
              <h3>{t('service2-title')}</h3>
              <p>{t('services2-description')}</p>
              <button className="button button--light">{t('services2-cta')}</button>
            </Link>
            {/* <Link to="/download" className="services__service">
              <h3>{t('service3-title')}</h3>
              <p>{t('services3-description')}</p>
              <button className="button button--light">{t('services3-cta')}</button>
            </Link> */}
          </div>
        </div>
        <div className="services__container services__container--companies">
          <div className="services__title">
            <h3>{t('services-company-title')}</h3>
          </div>
          <div className="services__links">
            {/* <a href="https://cognifya.com/" target="_blank" className="services__service">
              <img src={Cognifya} alt="Cognifya" />
            </a> */}
            <div className="services__service">
              <img src={EsmeePeters} alt="EsmeePeters" /> Freelance & Consultancy
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
