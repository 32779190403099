import React from 'react';

import InstgramIcon from '../images/icons/instagram.svg';
import LinkedInIcon from '../images/icons/linkedin.svg';
import SubstackIcon from '../images/icons/substack.svg';
import MailIcon from '../images/icons/mail.svg';

export default function SocialButtons() {
  return ( 
    <div className="share">
      <a className="share__item" href="https://www.linkedin.com/in/esmee-peters/" target="_blank">
        <img src={LinkedInIcon} alt="LinkedIn" />
      </a>
      <a className="share__item" href="https://www.instagram.com/esmee.a.peters/" target="_blank">
        <img src={InstgramIcon} alt="Instgram" />
      </a>
      <a className="share__item" href="https://devmore.substack.com/" target="_blank">
        <img src={SubstackIcon} alt="Substack" />
      </a>
      <a className="share__item" href="mailto:mail@esmeepeters.com">
        <img src={MailIcon} alt="Mail" />
      </a>
    </div>
  )
}
