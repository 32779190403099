import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Header from '../components/Header';
import Intro from '../components/Intro';
import Expertise from '../components/Expertise';
import Experience from '../components/Experience';
import Contact from '../components/Contact';

import Avatar from '../images/avatar-white.jpg';
import BackgroundImage from '../images/background/mobile.png';

export default function ProductDevelompent() {
  const { t, i18n } = useTranslation();
  const currentpage = 'freelance';

  return (
    <>
      <Helmet>
        <title>Freelance Front-End Developer & Product Owner Esmee Peters</title>
        <meta name="description" content="Freelance Front-End Developer & Freelance Product Owner" />
        <meta name="keywords" content="Product, Product Owner, Scrum, React, Angular, Javascript, Development, Developer" />
      </Helmet> 
      <div className={currentpage}>
        <Header 
          theme="dark"
          currentpage={currentpage}
          title={t('frl-development-header')}
          avatar={Avatar}
        />
        <Intro 
          currentpage={currentpage}
          leftColumn={BackgroundImage}
          rightColumnTitle={t('frl-development-intro-title')}
          rightColumnText={t('frl-development-intro-text')}
          mailLists={{ NL: [4], EN: [6] }}
        />
        {/* <Expertise
          currentpage={currentpage}
          title={t('frl-development-expertise-title')}
          expertiseArea={t('frl-development-expertise-skills')}
          expertise="development"
        />
        <Experience experience="development" /> */}
        <Contact 
          title={t('frl-development-contact')}
          description={t('contact-text')}
        />
      </div>
    </>
  )
}
