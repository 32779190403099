import React from 'react';
import { useTranslation } from "react-i18next";
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Project1 from '../images/projects/project1.jpg';
//import Project2 from '../images/projects/project2.jpg';
import Project3 from '../images/projects/project3.jpg';

export default function Work() {
  const { t, i18n } = useTranslation();

  return ( 
    <div className="work">
      <Swiper
        modules={[Navigation, A11y]}
        spaceBetween={8}
        slidesPerView={1.07}
        loop={true}
        navigation
      >
        <SwiperSlide>
          <div className="swiper-slide__container">
            <h3>{t('menu-work-projects1.0.title')}</h3>
            <p>{t('menu-work-projects1.1.paragraph')}</p>
          </div>
          <img src={Project1} alt={t('menu-work-projects1.0.title')} />
        </SwiperSlide>
        {/* <SwiperSlide>
          <div className="swiper-slide__container">
            <h3>{t('menu-work-projects2.0.title')}</h3>
            <p>{t('menu-work-projects2.1.paragraph')}</p>
          </div>
          <img src={Project2} />
        </SwiperSlide> */}
        <SwiperSlide>
          <div className="swiper-slide__container">
            <h3>{t('menu-work-projects3.0.title')}</h3>
            <p>{t('menu-work-projects3.1.paragraph')}</p>
          </div>
          <img src={Project3} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
