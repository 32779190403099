import React from 'react';
import { useTranslation } from "react-i18next";

import Availability from '../components/Availability';
import FormSubscription from '../components/FormSubscription';

interface Intro {
  currentpage: 'homepage' | 'freelance',
  leftColumn: string,
  rightColumnTitle?: string,
  rightColumnText: string,
  mailLists: { NL: [], EN: []}
}

export default function Intro({ currentpage, leftColumn, rightColumnTitle, rightColumnText, mailLists }: Intro) {
  const { t, i18n } = useTranslation();

  return ( 
    <section className="intro">
      <div className="grid">
        <div className="grid__column grid__column--three">
          <img src={leftColumn} alt={t('intro-background-image')} />
        </div>
        <div className="grid__column grid__column--three">
          {currentpage === 'homepage' && (
            <label>{t('intro-label')}</label>
          )}
          {rightColumnTitle && <h2>{rightColumnTitle}</h2> }
          <p>{rightColumnText}</p>
          <Availability 
            currentpage={currentpage}
            variant="subscription"
          />      
          <FormSubscription mailLists={mailLists} />
        </div>
      </div>
    </section>
  )
}
