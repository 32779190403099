import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import Header from "../components/Header";
import Intro from "../components/Intro";
import Expertise from "../components/Expertise";
import Experience from "../components/Experience";
import Contact from "../components/Contact";

import Avatar from "../images/avatar-white.jpg";
import BackgroundImage from "../images/background/mobile.png";

export default function ProductManagement() {
  const { t, i18n } = useTranslation();
  const currentpage = "freelance";

  return (
    <>
      <Helmet>
        <title>Freelance Product Owner & Product Manager Esmee Peters</title>
        <meta name="description" content="Freelance Product Owner and Product Manager" />
        <meta name="keywords" content="Product, Growth, Principal, Product Management, Product Owner, Scrum" />
      </Helmet>
      <div className={currentpage}>
        <Header
          theme="dark"
          currentpage={currentpage}
          title={t("frl-product-header")}
          avatar={Avatar}
        />
        <Intro
          currentpage={currentpage}
          leftColumn={BackgroundImage}
          rightColumnTitle={t("frl-product-intro-title")}
          rightColumnText={t("frl-product-intro-text")}
          mailLists={{ NL: [4], EN: [6] }}
        />
        <Expertise
          currentpage={currentpage}
          title={t("frl-product-expertise-title")}
          description={t("frl-product-expertise-text")}
          expertiseArea={t("frl-product-expertise-skills")}
          expertise="product"
        />
        <Experience experience="product" />
        <Contact 
          title={t("frl-product-contact")} 
          description={t("contact-text")} 
        />
      </div>
    </>
  );
}
