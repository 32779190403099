import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";

import Header from '../components/Header';
import Intro from '../components/Intro';
import Expertise from '../components/Expertise';
import Pricing from '../components/Pricing';
import Contact from '../components/Contact';

import Avatar from '../images/avatar-white.jpg';
import BackgroundImage from '../images/background/mobile.png';

export default function Consultancy() {
  const { t, i18n } = useTranslation();
  const currentpage = 'consultancy';

  return (
    <>
      <Helmet>
        <title>Product Consultant Esmee Peters</title>
        <meta name="description" content="Product consultancy" />
        <meta name="keywords" content="Product, Growth, Principal, Product Management, Product Owner, Scrum" />
        <link rel="canonical" href="https://esmeepeters.com" />
      </Helmet>
      <div className={currentpage}>
        <Header 
          theme ='dark'
          currentpage={currentpage}
          title={t('consultant-header')}
          avatar={Avatar}
        />
        <Intro 
          currentpage={currentpage}
          leftColumn={BackgroundImage}
          rightColumnTitle={t('product-intro-title')}
          rightColumnText={t('product-intro-text')}
        />
        <Expertise
          currentpage={currentpage}
          title={t('product-expertise-title')}
          description={t('product-expertise-text')}
          expertiseArea={t('product-expertise-skills')}
          expertise="product"
        />
        <Pricing />  
        <Contact 
          title={t('contact-title')}
          description={t('contact-text')}
        />
      </div>
    </>
  )
}