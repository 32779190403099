import React from 'react';
import { useTranslation } from "react-i18next";

import Socials1 from '../images/instagram/instagram_temp.jpg';

export default function Social() {
  const { t, i18n } = useTranslation();

  return ( 
    <section className="social">
      <div className="social__container">
        <a className="socialpost">
          <div className="socialpost__number">01</div>
          <div id="substack-feed-embed"></div>
        </a>
        <a className="socialpost">
          <div className="socialpost__number">02</div>
          <div id="substack-feed-embed"></div>
        </a>
        <a className="socialpost">
          <div className="socialpost__number">03</div>
          <div id="substack-feed-embed"></div>
          <div className="social__experience">
            <label>10+</label>
            <p>{t('social-experience')}</p>
          </div>
        </a>
        <div className="social__title">
          <p>{t('social-subtitle')}</p>
        </div>
        <a href="https://www.instagram.com/esmee.a.peters/" target="_blank" className="social__social-post">
          <img src={Socials1} />
        </a>
        <a href="https://www.instagram.com/esmee.a.peters/" target="_blank" className="social__social-post">
          <img src={Socials1} />
        </a>
        <a href="https://www.instagram.com/esmee.a.peters/" target="_blank" className="social__social-post">
          <img src={Socials1} />
        </a>
        <a href="https://www.instagram.com/esmee.a.peters/" target="_blank" className="social__social-post">
          <img src={Socials1} />
        </a>
        <div className="social__content">
         <p>{t('social-description')}</p>
        </div>
      </div>
    </section>
  )
}