import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Avatar from '../images/avatar-purple.jpg';

export default function PageNotFound() {
  const { t, i18n } = useTranslation();
  const currentpage: string = 'welcome';
  
  return (
    <div className={currentpage}>
      <Header 
        theme="light"
        currentpage={currentpage}
        title={t('homepage-header')}
        avatar={Avatar}
      />
      <section className="welcome__container">
        <h1>404</h1>
        <h2>{t('pageNotFound')}</h2>
        <iframe src="https://giphy.com/embed/KhliiAkDFP9YY"></iframe>
        <div className="welcome__options">
          <Link className="button button--long" to="/" >
            {t('pageNotFound-button')}
          </Link>
        </div>
      </section>
    </div>
  )
}