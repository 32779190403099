import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route, useLocation } from "react-router-dom";

import Homepage from "./pages/Homepage";
import Welcome from "./pages/Welcome";
import ProductManagement from "./pages/ProductManagement";
import ProductDevelompent from "./pages/ProductDevelompent";
import Consultancy from "./pages/Consultancy";
import Speaker from './pages/Speaker';
import Book from "./pages/Book";
import Download from "./pages/Download";
import PageNotFound from "./pages/PageNotFound";

export const LangContext = React.createContext(null);

export default function App() {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const [lang, setLang] = useState<string>(
    //(navigator.language || navigator.userLanguage) === "nl" ? "nl" : "en"
    "nl"
  );


  useEffect(() => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(localStorage.getItem("lang"));
  }, [lang]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [pathname]);

  return (
    <LangContext.Provider value={{ lang, setLang }}>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/productmanagement" element={<ProductManagement />} />
        <Route path="/productdevelopment" element={<ProductDevelompent />} />
        <Route path="/consultancy" element={<Consultancy />} />
        {/* <Route path="/speaker" element={<Speaker />} /> */}
        {/* <Route path="/ebook" element={<Book />} /> */}
        <Route path="/free-download" element={<Download />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </LangContext.Provider>
  );
}
