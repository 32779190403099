import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";

import Header from '../components/Header';
import Intro from '../components/Intro';
import Services from '../components/Services';
import Reviews from '../components/Reviews';
import Social from '../components/Social';
import Contact from '../components/Contact';

import Avatar from '../images/avatar-white.jpg';
import BackgroundImage from '../images/background/mobile.png';

export default function Homepage() {
  const { t, i18n } = useTranslation();
  const currentpage = 'homepage';

  return (
    <>
       <Helmet>
        <title>Esmee Peters</title>
        <meta name="description" content="Freelance Product Manager & Freelance Product Owner" />
        <meta name="keywords" content="Freelance, Product Manager, Product Owner, Product, Developer, Digital Strategy, SaaS, tech" />
        <script src="https://substackapi.com/embeds/feed.js" async></script>
      </Helmet> 
      <div className={currentpage}> 
        <Header 
          theme="cover"
          currentpage={currentpage}
          title={t('homepage-header-title')}
          subtitle={t('homepage-header-subtitle')}
          avatar={Avatar}
        />
        <Intro 
          currentpage={currentpage}
          leftColumn={BackgroundImage}
          rightColumnText={t('homepage-intro-text')}
          mailLists={{ NL: [4, 9], EN: [6, 8] }}
        />
        {/* <LogoOverview
          currentpage={currentpage}
          logos={logos}
        /> */}
        <Services 
          currentpage={currentpage}
          title={t('homepage-expertise-title')}
          description={t('homepage-expertise-text')}
        />
        <section className="context">
          {/* <div className="grid">
            <div className="grid__column grid__column--four">
              <label>{t('homepage-context-label')}</label>
              <h2 className="context__title">{t('homepage-context-title')}</h2>
              <p className="context__description">{t('homepage-context-text')}</p>
            </div>
          </div> */}
        </section>
        
        {/* <Reviews /> */}
        {(t("general-currentLang") === 'NL') && (
          <Social />
        )}
        <Contact 
          title={t('contact-title')}
          description={t('contact-text')}
        />
      </div>
    </>
  );
}
