import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import Menu from './Menu';
import MenuLogo from '../images/icons/logo';
import NavItems from '../components/NavItems';
import MenuIcon from '../images/icons/menu';
import { LangContext } from '../App';

type Theme = 'light' | 'dark';
interface Nav {
  currentpage : string,
  theme : Theme
}

export default function Navigation({currentpage, theme} : Nav) {
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);

  const { lang, setLang } = useContext(LangContext);

  useEffect(() => {
    document.body.classList.toggle('menu--open', menuOpen)
  }, [menuOpen])

  return ( 
    <>
      <Menu 
        setMenuOpen={setMenuOpen}
        isMenuOpen={menuOpen} 
      />
      <nav className={`nav--${theme}`}>
        <div className="nav__logo">
          <Link to="/"> 
            <MenuLogo />
          </Link>
        </div>
        <ul className="nav__items">
          <NavItems />
        </ul>
        <div className="nav__action">
          <button className="nav__lang" onClick={() => setLang(lang === 'en' ? 'nl' : 'en')}>
            {t('general-changeLang')}
          </button>
          <a href="mailto:mail@esmeepeters.com" className="nav__contact">{t('nav-contact-long')}</a>
          <button aria-label="open menu" className="nav__menu" onClick={toggleMenu}>
            <MenuIcon />
          </button>
        </div>
      </nav>
    </>
  )
}
