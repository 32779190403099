import React from 'react';
import { useTranslation } from "react-i18next";

export default function App() {
  const { t, i18n } = useTranslation();
  const tags : string[] = ['Entrepreneur', 'Founder', 'Product Manager', 'Learner', 'Digital Product Design', 'Business Development' ]

  return (
    <div className="timeline">
      <div className="timeline__year">
        2024
      </div>
      {/* <div className="timeline__post">
        <div className="timeline__post-date">
          {t('timeline-post6.0.date')}
        </div>
        <div className="timeline__post-title">
          {t('timeline-post6.1.title')}
        </div>
        <div className="tag-list">
        </div>
        <div className="timeline__post-content">
          {t('timeline-post6.2.paragraph')}
        </div>
      </div> */}
      <div className="timeline__post">
        <div className="timeline__post-date">
          {t('timeline-post5.0.date')}
        </div>
        <div className="timeline__post-title">
          {t('timeline-post5.1.title')}
        </div>
        <div className="tag-list">
          <div className="tag tag--colored">{tags[2]}</div>
        </div>
        <div className="timeline__post-content">
          {t('timeline-post5.2.paragraph')}
        </div>
      </div>
      <div className="timeline__year">
        2023
      </div>
      <div className="timeline__post">
        <div className="timeline__post-date">
          {t('timeline-post4.0.date')}
        </div>
        <div className="timeline__post-title">
          {t('timeline-post4.1.title')}
        </div>
        <div className="tag-list">
          <div className="tag tag--colored">{tags[2]}</div>
          <div className="tag tag--colored">{tags[4]}</div>
          <div className="tag tag--colored">{tags[5]}</div>
        </div>
        <div className="timeline__post-content">
          {t('timeline-post4.2.paragraph')}
        </div>
      </div>
      <div className="timeline__post">
        <div className="timeline__post-date">
          {t('timeline-post3.0.date')}
        </div>
        <div className="timeline__post-title">
          {t('timeline-post3.1.title')}
        </div>
        <div className="tag-list">
          <div className="tag tag--colored">{tags[2]}</div>
          <div className="tag tag--colored">{tags[3]}</div>
        </div>
        <div className="timeline__post-content">
          {t('timeline-post3.2.paragraph')}
        </div>
      </div>
      <div className="timeline__year">
        2022
      </div>
      <div className="timeline__post">
        <div className="timeline__post-date">
          {t('timeline-post2.0.date')}
        </div>
        <div className="timeline__post-title">
          {t('timeline-post2.1.title')}
        </div>
        <div className="tag-list">
          <div className="tag tag--colored">{tags[2]}</div>
        </div>
        <div className="timeline__post-content">
          {t('timeline-post2.2.paragraph')}
        </div>
      </div>
      <div className="timeline__post">
        <div className="timeline__post-date">
          {t('timeline-post1.0.date')}
        </div>
        <div className="timeline__post-title">
          {t('timeline-post1.1.title')}
        </div>
        <div className="tag-list">
          <div className="tag tag--colored">{tags[0]}</div>
          <div className="tag tag--colored">{tags[1]}</div>
        </div>
        <div className="timeline__post-content">
          {t('timeline-post1.2.paragraph')}
        </div>
      </div>
    </div> 
  )
}
