import React from 'react';
import { useTranslation } from "react-i18next";

import LogoLoveren from '../images/logos/companies/loveren.jpg';
import LogoPlanon from '../images/logos/companies/planon.jpg';
import LogoSmartHealth from '../images/logos/companies/smarthealth.jpg';
import LogoOuwhand from '../images/logos/companies/ouwehand.jpg';

export default function Accordion() {
  const { t, i18n } = useTranslation();
 
  return ( 
    <div className="accordion">
      <div className='accordion__label'>
        {t('accordion-label')}
      </div>
      {/* <div className="accordion__item">
        <div className="accordion__header">
          <img className="accordion__image" src={LogoPlanon} alt="Cognifya" />
          <div className="accordion__info">
            <h3>Head of Product</h3>
            <div className="accordion__company">Cognifya</div>
            <div className="accordion__time">{t('accordion-date3')}</div>
          </div>
        </div>
      </div> */}
      <div className="accordion__item">
        <div className="accordion__header">
          <img className="accordion__image" src={LogoLoveren} alt="Planon" />
          <div className="accordion__info">
            <h3>Product Advisor</h3>
            <div className="accordion__company">Van Loveren & Partners</div>
            <div className="accordion__time">{t('accordion-date4')}</div>
          </div>
        </div>
      </div>
      <div className="accordion__item">
        <div className="accordion__header">
          <img className="accordion__image" src={LogoPlanon} alt="Planon" />
          <div className="accordion__info">
            <h3>Product Owner</h3>
            <div className="accordion__company">Planon</div>
            <div className="accordion__time">{t('accordion-date3')}</div>
          </div>
        </div>
      </div>
      <div className="accordion__item">
        <div className="accordion__header">
          <img className="accordion__image" src={LogoSmartHealth} alt="Smart Health" />
          <div className="accordion__info">
            <h3>Product Manager</h3>
            <div className="accordion__company">Smart Health</div>
            <div className="accordion__time">{t('accordion-date2')}</div>
          </div>  
        </div>
      </div> 
      <div className="accordion__item">
        <div className="accordion__header">
          <img className="accordion__image" src={LogoOuwhand} alt="Ouwehand" />
          <div className="accordion__info">
            <h3>Product Manager</h3>
            <div className="accordion__company">{t('accordion-company1')}</div>
            <div className="accordion__time">{t('accordion-date1')}</div>
          </div>      
        </div>
      </div>
      <div className="accordion__link">
        <a href="https://www.linkedin.com/in/esmee-peters/" target="_blank" arial-label="Go to LinkedIn" className="link link--large">
          {t('accordion-link')}
        </a>
      </div>
    </div>
  )
}
