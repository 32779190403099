import React from 'react';
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation } from 'swiper';

import pspoi from '../images/logos/certificate/pspoi.png';
import pspoii from '../images/logos/certificate/pspoii.png';
import psmi from '../images/logos/certificate/psmi.png';
import dasa from '../images/logos/certificate/dasa.png';
import html from '../images/logos/skills/html.png';
import css from '../images/logos/skills/css.png';
import sass from '../images/logos/skills/sass.png';
import javascript from '../images/logos/skills/javascript.png';
import typescript from '../images/logos/skills/typescript.png';
import react from '../images/logos/skills/react.png';
import next from '../images/logos/skills/next.svg';
import vue from '../images/logos/skills/vue.png';
import angular from '../images/logos/skills/angular.png';

interface Tiles {
  experience: 'product' | 'development'
}

export default function Tiles({experience}: Tiles) {
  const { t, i18n } = useTranslation();
  const productSkills = [
    {
      img: pspoi,
      title: 'Professional Scrum Product Owner I',
      company: 'Scrum.org'
    },
    {
      img: pspoii,
      title: 'Professional Scrum Product Owner II',
      company: 'Scrum.org'
    },
    {
      img: psmi,
      title: 'Professional Scrum Master I',
      company: 'Scrum.org'
    },
    {
      img: dasa,
      title: 'DASA DevOps',
      company: 'APMG International'
    }
  ];

  const developmentSkills = [
    {
      img: pspoii,
      title: 'Professional Scrum Product Owner II',
      company: 'Scrum.org'
    },
    {
      img: psmi,
      title: 'Professional Scrum Master I',
      company: 'Scrum.org'
    },
    {
      img: html,
      title: 'HTML',
    },
    {
      img: css,
      title: 'CSS',
    },
    {
      img: sass,
      title: 'Sass',
    },
    {
      img: javascript,
      title: 'Javascript',
    },
    {
      img: typescript,
      title: 'Typescript',
    },
    {
      img: react,
      title: 'React',
    },
    {
      img: next,
      title: 'NextJS',
    },
    {
      img: vue,
      title: 'Vue',
    },
    {
      img: angular,
      title: 'Angular',
    }
  ];

  const skills = experience === 'product' ? productSkills : developmentSkills;

  return (
    <>
      <div className={`tiles tiles--${experience}`}>
        {skills.map((skill, index) => {
          return (
            <div key={index} className="tiles__tile">
              <div className="tiles__icon">
                <img src={ skill.img } alt={skill.title} />
              </div>
              <div className="tiles__text">
                { skill.title }       
              </div>
              <div className="tiles__company">
                { skill.company }
              </div>
            </div>   
          )
        })}
      </div>
      <div className="tiles tiles--slider">
        <Swiper
          modules={[A11y, Navigation]}
          spaceBetween={15}
          slidesPerView={1.2}
          speed={1000}
          loop={true}
          navigation
          breakpoints={{
            350: { slidesPerView: 2.2 },
            515: { slidesPerView: 3.2, }
          }}
        >
          {skills.map((skill, index) => {
            return (
              <SwiperSlide key={index}>
                <div key={index} className="tiles__tile">
                  <div className="tiles__icon">
                    <img src={ skill.img } alt={skill.title} />
                  </div>
                  <div className="tiles__text">
                    { skill.title }       
                  </div>
                  <div className="tiles__company">
                    { skill.company }
                  </div>
                </div>   
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </>
  )
}
