import React from 'react';
import { useTranslation } from "react-i18next";
import Tiles from './Tiles';
import Accordion from './Accordion';

interface Experience {
  experience: 'product' | 'development'
}

export default function Experience({experience}: Experience) {
  const { t, i18n } = useTranslation();

  const yearsInSoftware : number = 10; // Start year 2013
  const yearsInProduct : number = new Date().getFullYear() - 2021;
  const yearsInDevelopment : number = 5 + new Date().getFullYear() - 2024;
  
  return ( 
    <div className="experience">
      <div className="experience__title grid">
        <div className="grid__column grid__column--two"></div>
        <div className="grid__column grid__column--four">
          <div className="experience__label">{t(`frl-${experience}-experience-label`)}</div>
          <h2>{t(`frl-${experience}-experience-title`)}</h2>
          <p>{t(`frl-${experience}-experience-text.0.text`)}{yearsInSoftware}{t(`frl-${experience}-experience-text.1.text`)}{t(`frl-${experience}-experience-text.2.text`)}{experience === 'product' ? yearsInProduct : yearsInDevelopment}{t(`frl-${experience}-experience-text.1.text`)}{t(`frl-${experience}-experience-text.3.text`)}</p>
        </div>
      </div>
      <div className="grid">
        <div className="grid__column grid__column--two">
          <div className="experience__highlight">
            <p>{t(`frl-${experience}-experience-text.0.text`)}<span>{yearsInSoftware}{t(`frl-${experience}-experience-text.1.text`)}</span>{t(`frl-${experience}-experience-text.2.text`)}<span>{experience === 'product' ? yearsInProduct : yearsInDevelopment}{t(`frl-${experience}-experience-text.1.text`)}</span>{t(`frl-${experience}-experience-text.3.text`)}</p>
          </div>
          <Tiles experience={experience} />
        </div>
        <div className="grid__column grid__column--four">
          <Accordion />
        </div>
      </div>
    </div>
  )
}
